import { env } from '../env';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import {
  // configureScope,
  init,
} from '@sentry/react';

// logging
if (!env.isDevelopment && env.IS_RTM) {
  // We've disabled logrocket for now, to save quota and use it in Ancient World
  // // LogRocket
  // LogRocket.init(env.LOG_ROCKET_ID, {
  //   release: env.GIT_HASH,
  // });

  // We've disabled logrocket for now, to save quota and use it in Ancient World
  // setupLogRocketReact(LogRocket);

  // Sentry initialiation,
  // Make sure Sentry CLI is configured to create a release when deploying.
  if (env.GIT_HASH) {
    init({
      dsn: env.SENTRY_DSN,
      release: env.GIT_HASH,
      environment: env.ENVIRONMENT,
    });

    // We've disabled logrocket for now, to save quota and use it in Ancient World
    // // Integrate LogRocket with Sentry
    // // refer: https://blog.logrocket.com/extending-sentry-with-logrocket-52e2f5b67d5a/
    // configureScope((scope) => {
    //   scope.setExtra('sessionURL', LogRocket.sessionURL);
    // });
  }
}
