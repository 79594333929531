import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import LogRocket from 'logrocket';
import { configureScope } from '@sentry/react';
import { currentUserID } from '../../store/auth/selectors';

// better to do this in a saga
export const StateListener = () => {
  const userID = useSelector(currentUserID);

  useEffect(() => {
    if (userID) {
      configureScope(function (scope) {
        scope.setUser({ id: userID });
      });

      // LogRocket.identify(userID);
    }
  }, [userID]);

  return null;
};
