import { Typography } from '@material-ui/core';
import { useEffect } from 'react';

type Props = {
  error: Error;
};

export const ErrorScreen = ({ error }: Props) => {
  useEffect(() => {
    if (error.message) {
      console.error(error.message);
    }
  }, [error.message]);

  return <Typography color="error">Error {error.message}</Typography>;
};
