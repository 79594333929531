import { Button } from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { noop } from 'ramda-adjunct';
import { generateQRCode } from '../utils/generateQRCode';
import { getQRCodeLink } from '../utils/getQRCodeLink';

type Props = {
  document: any;
  documentProps: any;
  filename?: string;
  className?: string;
  style?: any;
  onDownloadSuccess?: () => void;
};

const DownloadActivationPDF = ({
  document: Doc,
  documentProps: { client, redeemCode, appURL },
  filename = 'Activation.pdf',
  className,
  style = {},
  onDownloadSuccess = noop,
}: Props) => {
  const generateActivationPDF = async () => {
    const activationUrl = getQRCodeLink({
      redeemCode,
      appURL,
    });

    const qrCodeDataImgUrl = await generateQRCode(activationUrl);

    const logoURL = client?.logoImage?.uri;

    const processedProps = {
      activationUrl,
      qrCodeDataImgUrl,
      redeemCode,
      appURL,
      logoURL,
    };

    const blob = await pdf(<Doc {...processedProps} />).toBlob();

    saveAs(blob, filename);

    onDownloadSuccess();
  };

  return (
    <Button
      variant="contained"
      size="large"
      color="primary"
      onClick={generateActivationPDF}
      className={className}
      style={style}
    >
      Download PDF
    </Button>
  );
};

export default DownloadActivationPDF;
