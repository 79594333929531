import { path } from 'ramda';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { HTTP_STATUS } from '../../httpStatus';
import { getStore } from '../../../store';
import { signOut } from '../../../store/auth/actions';

const graphqlExceptionCode = path<number>(['extensions', 'exception', 'code']);

function handleApolloError({
  graphQLErrors,
  networkError,
  response,
}: ErrorResponse) {
  let errorRequiringReAuth;

  // if graphQLErrors are an array
  if (Array.isArray(graphQLErrors)) {
    errorRequiringReAuth = graphQLErrors.find((err) => {
      // get the exception code from the each error
      const code = graphqlExceptionCode(err);

      // check if it is any of these types
      return (
        code === HTTP_STATUS.UPGRADE_REQUIRED ||
        code === HTTP_STATUS.UNAUTHORIZED
      );
    });
  }

  if (
    errorRequiringReAuth ||
    // @ts-ignore
    networkError?.statusCode === HTTP_STATUS.UNAUTHORIZED
  ) {
    window.alert('Your session has expired.\nPlease log back in to continue');

    const store = getStore();
    store.dispatch(signOut());
  }
}

export const handleApolloErrorLink = onError(handleApolloError);
